import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Input, Button, Space, Layout, Typography, message, Spin, Tag, Select, Switch, Modal, List, Dropdown, Menu } from 'antd';
import { MinusCircleOutlined, PlusOutlined, CopyOutlined, PlayCircleOutlined, ReloadOutlined, SaveOutlined, UploadOutlined, DownloadOutlined, DeleteOutlined, EditOutlined, SettingOutlined } from '@ant-design/icons';
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism.css';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";

const { TabPane } = Tabs;
const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const StyledContent = styled.div`
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  height: calc(100vh - 70px - 32px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StyledTabContent = styled.div`
  flex: 1;
  overflow: hidden;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

const ScrollableContent = styled.div`
  height: 400px;
  overflow-y: auto;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`;

const EmailIframeWrapper = styled(ScrollableContent)`
  display: flex;
  flex-direction: column;
`;

const EmailIframe = styled.iframe`
  flex: 1;
  width: 100%;
  border: none;
`;

const TagsWrapper = styled(ScrollableContent)`
  padding: 16px;
`;

const TagExpressionInput = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  background-color: #f0f2f5;
  border-radius: 4px;
  padding: 4px;
  position: relative;
`;

const StyledEditor = styled(Editor)`
  flex-grow: 1;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px 11px;
  background-color: white;
  font-family: 'Fira code', 'Fira Mono', monospace;
  font-size: 14px;
  line-height: 1.5;
  min-height: 36px;
`;

const EvaluatedValue = styled.span`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: ${props => props.error ? 'red' : '#1890ff'};
  font-style: italic;
  max-width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
  user-select: none;
  opacity: ${props => props.editing ? 0 : 1};
  transition: opacity 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2px 6px;
  border-radius: 3px;
`;


const TaggingContainer = styled.div`
  background-color: #f0f2f5;
  padding: 16px;
  border-radius: 4px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`;

const CallEvaluationView = () => {
    const [transcript, setTranscript] = useState('');
    const [callJSON, setCallJSON] = useState('');
    const [showCallJSON, setShowCallJSON] = useState(false);
    const [aiQuestions, setAiQuestions] = useState('');
    const [emailTemplate, setEmailTemplate] = useState('');
    const [tagExpressions, setTagExpressions] = useState(['']);
    const [evaluatedExpressions, setEvaluatedExpressions] = useState([]);
    const [editingExpressionIndex, setEditingExpressionIndex] = useState(null);
    const [showTagTable, setShowTagTable] = useState(true);
    const [answers, setAnswers] = useState('');
    const [emailHtml, setEmailHtml] = useState('');
    const [tags, setTags] = useState([]);
    const [activeTab, setActiveTab] = useState('input');
    const [loading, setLoading] = useState(false);
    const [initializing, setInitializing] = useState(true);
    const [tenants, setTenants] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState('');
    const [savedConfigs, setSavedConfigs] = useState([]);
    const [isLoadModalVisible, setIsLoadModalVisible] = useState(false);
    const [currentConfigName, setCurrentConfigName] = useState(null);

    const iframeRef = useRef(null);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const configs = JSON.parse(localStorage.getItem('savedConfigurations')) || [];
        setSavedConfigs(configs);
    }, []);

    useEffect(() => {
        fetchTenants();
    }, []);

    useEffect(() => {
        if (selectedTenant) {
            fetchStarterValues();
        }
    }, [selectedTenant]);

    useEffect(() => {
        if (iframeRef.current && emailHtml) {
            const iframe = iframeRef.current;
            iframe.src = 'about:blank';

            setTimeout(() => {
                iframe.src = `data:text/html;charset=utf-8,${encodeURIComponent(emailHtml)}`;
            }, 0);
        }
    }, [emailHtml]);

    useEffect(() => {
        if (isValidJSON(callJSON)) {
            tagExpressions.forEach((expr, index) => evaluateExpression(index, expr));
        }
    }, [callJSON]);

    const fetchTenants = async () => {
        try {
            const token = await getAccessTokenSilently({
                audience: "https://audience.auth0.leadlensai.com",
                scope: "openid profile email",
            });
            const response = await axios.get('/api/tenants/list', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTenants(response.data);
            if (response.data.length > 0) {
                setSelectedTenant(response.data[0]);
            }
        } catch (error) {
            message.error('Error fetching tenants: ' + error.message);
        }
    };

    const fetchStarterValues = async () => {
        setInitializing(true);
        try {
            const token = await getAccessTokenSilently({
                audience: "https://audience.auth0.leadlensai.com",
                scope: "openid profile email",
            });
            const response = await axios.get('/api/evaluate-call-templates', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { tenant: selectedTenant },
            });
            setTranscript(response.data.sampleTranscript);
            setAiQuestions(JSON.stringify(response.data.sampleAIQuestions, null, 2));
            setEmailTemplate(response.data.sampleEmailTemplate);
            setTagExpressions(response.data.sampleTagExpressions.split(','));
        } catch (error) {
            message.error('Error fetching starter values: ' + error.message);
        } finally {
            setInitializing(false);
        }
    };

    const handleRun = async () => {
        setLoading(true);
        try {
            const token = await getAccessTokenSilently({
                audience: "https://audience.auth0.leadlensai.com",
                scope: "openid profile email",
            });
            const payload = {
                transcript,
                aiQuestions,
                emailTemplate,
                tagExpressions: tagExpressions.join(','),
                tenant: selectedTenant,
            };

            if (callJSON && isValidJSON(callJSON) && transcript.indexOf(' ') !== -1) {
                payload.call = JSON.parse(callJSON);
            }

            const response = await axios.post('/api/evaluate-call', payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setAnswers(JSON.stringify(response.data.answers, null, 2));
            setEmailHtml(response.data.email);
            setTags(Array.isArray(response.data.tags) ? response.data.tags : []);
            setActiveTab('output');
            if (transcript && response.data.transcript && transcript.indexOf(' ') === -1) {
                setTranscript(response.data.transcript);
            }
            if (response.data.call) {
                setCallJSON(JSON.stringify(response.data.call, null, 2));
            }
        } catch (error) {
            message.error('Error evaluating call: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleTagExpressionChange = (index, value) => {
        const newExpressions = [...tagExpressions];
        newExpressions[index] = value;
        setTagExpressions(newExpressions);
        evaluateExpression(index, value);
    };

    const handleExpressionFocus = (index) => {
        setEditingExpressionIndex(index);
    };

    const handleExpressionBlur = () => {
        setEditingExpressionIndex(null);
    };

    const evaluateExpression = (index, expression) => {
        if (!callJSON || !isValidJSON(callJSON)) {
            setEvaluatedExpressions(prev => {
                const newEvaluations = [...prev];
                newEvaluations[index] = { value: '', error: false };
                return newEvaluations;
            });
            return;
        }

        try {
            const call = JSON.parse(callJSON);
            // eslint-disable-next-line no-new-func
            const result = new Function('call', `return ${expression}`)(call);
            setEvaluatedExpressions(prev => {
                const newEvaluations = [...prev];
                newEvaluations[index] = { value: JSON.stringify(result), error: false };
                return newEvaluations;
            });
        } catch (error) {
            setEvaluatedExpressions(prev => {
                const newEvaluations = [...prev];
                newEvaluations[index] = { value: error.message, error: true };
                return newEvaluations;
            });
        }
    };

    const addTagExpression = () => {
        setTagExpressions([...tagExpressions, '']);
    };

    const removeTagExpression = (index) => {
        const newExpressions = tagExpressions.filter((_, i) => i !== index);
        setTagExpressions(newExpressions);
    };

    const highlightWithoutLineNumbers = (input, language) => {
        return Prism.highlight(input, Prism.languages[language], language);
    };

    const copyTagExpressions = () => {
        const expressionsText = tagExpressions.join(',');
        navigator.clipboard.writeText(expressionsText).then(() => {
            message.success('Tag expressions copied to clipboard');
        }, () => {
            message.error('Failed to copy tag expressions');
        });
    };

    const isValidJSON = (str) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    };

    const editorStyle = {
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 14,
        width: '100%',
        minHeight: '100%',
        backgroundColor: '#ffffff',
        lineHeight: '1.5',
    };

    const saveConfiguration = (name, isOverwrite = false) => {
        const configName = isOverwrite ? name : prompt("Enter a name for this configuration:", name || "");
        if (configName) {
            const newConfig = {
                name: configName,
                date: new Date().toISOString(),
                data: {
                    transcript,
                    callJSON,
                    aiQuestions,
                    emailTemplate,
                    tagExpressions
                }
            };

            let updatedConfigs;
            if (isOverwrite) {
                updatedConfigs = savedConfigs.map(config =>
                    config.name === configName ? newConfig : config
                );
            } else {
                updatedConfigs = [...savedConfigs, newConfig];
            }

            localStorage.setItem('savedConfigurations', JSON.stringify(updatedConfigs));
            setSavedConfigs(updatedConfigs);
            setCurrentConfigName(configName);
            message.success(`Configuration ${isOverwrite ? 'updated' : 'saved'} successfully`);
        }
    };

    const loadConfiguration = (config) => {
        setTranscript(config.data.transcript);
        setCallJSON(config.data.callJSON);
        setAiQuestions(config.data.aiQuestions);
        setEmailTemplate(config.data.emailTemplate);
        setTagExpressions(config.data.tagExpressions);
        setCurrentConfigName(config.name);
        setIsLoadModalVisible(false);
        message.success('Configuration loaded successfully');
    };

    const deleteConfiguration = (configName) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this configuration?',
            content: 'This action cannot be undone.',
            onOk() {
                const updatedConfigs = savedConfigs.filter(config => config.name !== configName);
                localStorage.setItem('savedConfigurations', JSON.stringify(updatedConfigs));
                setSavedConfigs(updatedConfigs);
                if (currentConfigName === configName) {
                    setCurrentConfigName(null);
                }
                message.success('Configuration deleted successfully');
            },
        });
    };

    const exportConfiguration = () => {
        const config = {
            transcript,
            callJSON,
            aiQuestions,
            emailTemplate,
            tagExpressions
        };
        const blob = new Blob([JSON.stringify(config)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${currentConfigName || 'configuration'}.json`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const importConfiguration = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const config = JSON.parse(e.target.result);
                    setTranscript(config.transcript);
                    setCallJSON(config.callJSON);
                    setAiQuestions(config.aiQuestions);
                    setEmailTemplate(config.emailTemplate);
                    setTagExpressions(config.tagExpressions);
                    setCurrentConfigName(null);
                    message.success('Configuration imported successfully');
                } catch (error) {
                    message.error('Error importing configuration');
                }
            };
            reader.readAsText(file);
        }
    };

    const handleMenuClick = (e) => {
        switch (e.key) {
            case 'save':
                saveConfiguration(currentConfigName, true);
                break;
            case 'saveAs':
                saveConfiguration();
                break;
            case 'load':
                setIsLoadModalVisible(true);
                break;
            case 'export':
                exportConfiguration();
                break;
            case 'import':
                document.getElementById('import-config').click();
                break;
            default:
                break;
        }
    };

    const configMenu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="save" icon={<SaveOutlined />} disabled={!currentConfigName}>
                Save
            </Menu.Item>
            <Menu.Item key="saveAs" icon={<EditOutlined />}>
                Save As
            </Menu.Item>
            <Menu.Item key="load" icon={<UploadOutlined />}>
                Load Configuration
            </Menu.Item>
            <Menu.Item key="export" icon={<DownloadOutlined />}>
                Export Configuration
            </Menu.Item>
            <Menu.Item key="import" icon={<UploadOutlined />}>
                Import Configuration
            </Menu.Item>
        </Menu>
    );

    const saveMenu = (
        <Menu>
            <Menu.Item key="1" onClick={() => saveConfiguration(currentConfigName, true)} disabled={!currentConfigName}>
                Save
            </Menu.Item>
            <Menu.Item key="2" onClick={() => saveConfiguration()}>
                Save As
            </Menu.Item>
        </Menu>
    );

    const renderInputTabs = () => (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Test Transcript" key="1">
                <ScrollableContent>
                    {showCallJSON ? (
                        <Editor
                            value={callJSON}
                            onValueChange={code => setCallJSON(code)}
                            highlight={code => highlightWithoutLineNumbers(code, 'json')}
                            style={editorStyle}
                            padding={10}
                        />
                    ) : (
                        <Input.TextArea
                            value={transcript}
                            onChange={(e) => setTranscript(e.target.value)}
                            placeholder="Paste or type call transcript here"
                            style={{ ...editorStyle, resize: 'none', border: 'none' }}
                        />
                    )}
                </ScrollableContent>
                <ToggleWrapper>
                    <Switch
                        checkedChildren="JSON"
                        unCheckedChildren="Transcript"
                        checked={showCallJSON}
                        onChange={setShowCallJSON}
                    />
                </ToggleWrapper>
            </TabPane>
            <TabPane tab="JSON AI Questions" key="2">
                <ScrollableContent>
                    <Editor
                        value={aiQuestions}
                        onValueChange={code => setAiQuestions(code)}
                        highlight={code => highlightWithoutLineNumbers(code, 'json')}
                        style={editorStyle}
                        padding={10}
                    />
                </ScrollableContent>
            </TabPane>
            <TabPane tab="Email Template" key="3">
                <ScrollableContent>
                    <Editor
                        value={emailTemplate}
                        onValueChange={code => setEmailTemplate(code)}
                        highlight={code => highlightWithoutLineNumbers(code, 'markup')}
                        style={editorStyle}
                        padding={10}
                    />
                </ScrollableContent>
            </TabPane>
            <TabPane tab="Tagging" key="4">
                <ScrollableContent>
                    <TaggingContainer>
                        {showTagTable ? (
                            tagExpressions.map((expr, index) => (
                                <TagExpressionInput key={index}>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <StyledEditor
                                            value={expr}
                                            onValueChange={code => handleTagExpressionChange(index, code)}
                                            onFocus={() => handleExpressionFocus(index)}
                                            onBlur={handleExpressionBlur}
                                            highlight={code => highlightWithoutLineNumbers(code, 'javascript')}
                                            style={{
                                                ...editorStyle,
                                                minHeight: '36px',
                                                height: 'auto',
                                            }}
                                            padding={10}
                                        />
                                        <EvaluatedValue
                                            error={evaluatedExpressions[index]?.error}
                                            editing={editingExpressionIndex === index}
                                        >
                                            {evaluatedExpressions[index]?.value}
                                        </EvaluatedValue>
                                    </div>
                                    <Button
                                        type="text"
                                        icon={<MinusCircleOutlined />}
                                        onClick={() => removeTagExpression(index)}
                                        style={{ marginLeft: 8 }}
                                    />
                                </TagExpressionInput>
                            ))
                        ) : (
                            <Editor
                                value={tagExpressions.join(',')}
                                onValueChange={(code) => setTagExpressions(code.split(','))}
                                highlight={code => highlightWithoutLineNumbers(code, 'javascript')}
                                style={editorStyle}
                                padding={10}
                            />
                        )}
                        <Space style={{ marginTop: 16, width: '100%', justifyContent: 'space-between' }}>
                            <Button
                                type="dashed"
                                onClick={addTagExpression}
                                icon={<PlusOutlined />}
                            >
                                Add Tag Expression
                            </Button>
                            <Button
                                type="primary"
                                icon={<CopyOutlined />}
                                onClick={copyTagExpressions}
                            >
                                Copy Expressions
                            </Button>
                        </Space>
                    </TaggingContainer>
                </ScrollableContent>
                <ToggleWrapper>
                    <Switch
                        checkedChildren="Table"
                        unCheckedChildren="Text"
                        checked={showTagTable}
                        onChange={setShowTagTable}
                    />
                </ToggleWrapper>
            </TabPane>
        </Tabs>
    );

    const renderOutputTabs = () => (
        <Tabs defaultActiveKey="1">
            <TabPane tab="AI Answers" key="1">
                <ScrollableContent>
                    <Editor
                        value={answers}
                        onValueChange={() => {}}
                        highlight={code => highlightWithoutLineNumbers(code, 'json')}
                        style={editorStyle}
                        padding={10}
                        readOnly={true}
                    />
                </ScrollableContent>
            </TabPane>
            <TabPane tab="Rendered Email" key="2">
                <EmailIframeWrapper>
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <EmailIframe
                            ref={iframeRef}
                            title="Rendered Email"
                            sandbox="allow-scripts allow-same-origin"
                        />
                    )}
                </EmailIframeWrapper>
            </TabPane>
            <TabPane tab="Tags" key="3">
                <TagsWrapper>
                    {Array.isArray(tags) && tags.length > 0 ? (
                        tags.map((tag, index) => (
                            <Tag key={index} color="blue" style={{ margin: '0 8px 8px 0' }}>
                                {tag}
                            </Tag>
                        ))
                    ) : (
                        <p>No tags available</p>
                    )}
                </TagsWrapper>
            </TabPane>
        </Tabs>
    );

    if (initializing) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin size="large" />
            </div>
        );
    }

    return (
        <Layout style={{ height: '100%', overflow: 'hidden' }}>
            <StyledContent>
                <HeaderContainer>
                    <Title level={3}>Configuration Testing</Title>
                    <Space>
                        <Typography.Text strong>Tenant:</Typography.Text>
                        <Select
                            style={{ width: 200 }}
                            value={selectedTenant}
                            onChange={(value) => setSelectedTenant(value)}
                            placeholder="Select a tenant"
                        >
                            {tenants.map((tenant) => (
                                <Option key={tenant} value={tenant}>{tenant}</Option>
                            ))}
                        </Select>
                    </Space>
                </HeaderContainer>
                <StyledTabContent>
                    <Tabs activeKey={activeTab} onChange={setActiveTab} style={{ height: '100%' }}>
                        <TabPane tab="Input" key="input" style={{ height: '100%' }}>
                            {renderInputTabs()}
                        </TabPane>
                        <TabPane tab="Output" key="output" style={{ height: '100%' }}>
                            {renderOutputTabs()}
                        </TabPane>
                    </Tabs>
                </StyledTabContent>
                <Space>
                    <Button
                        type="primary"
                        icon={<PlayCircleOutlined />}
                        onClick={handleRun}
                        loading={loading}
                        disabled={!selectedTenant}
                    >
                        Run Evaluation
                    </Button>
                    <Button
                        icon={<ReloadOutlined />}
                        onClick={fetchStarterValues}
                        disabled={!selectedTenant}
                    >
                        Revert Configuration
                    </Button>
                    <Dropdown overlay={configMenu}>
                        <Button icon={<SettingOutlined />}>
                            Configuration Options
                        </Button>
                    </Dropdown>
                    <input
                        type="file"
                        accept=".json"
                        style={{ display: 'none' }}
                        onChange={importConfiguration}
                        id="import-config"
                    />
                </Space>
            </StyledContent>
            <Modal
                title="Load Configuration"
                visible={isLoadModalVisible}
                onCancel={() => setIsLoadModalVisible(false)}
                footer={null}
            >
                <List
                    dataSource={savedConfigs}
                    renderItem={item => (
                        <List.Item
                            actions={[
                                <Button
                                    key="load"
                                    type="primary"
                                    onClick={() => loadConfiguration(item)}
                                >
                                    Load
                                </Button>,
                                <Button
                                    key="delete"
                                    type="danger"
                                    icon={<DeleteOutlined />}
                                    onClick={() => deleteConfiguration(item.name)}
                                />
                            ]}
                        >
                            <List.Item.Meta
                                title={item.name}
                                description={new Date(item.date).toLocaleString()}
                            />
                        </List.Item>
                    )}
                />
            </Modal>
        </Layout>
    );
};

export default CallEvaluationView;
